import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Player, BigPlayButton, ControlBar } from "video-react";
import getImagePath from "helpers/get-image-path";
import getFilePath from "helpers/get-file-path";
import playVideoButton from "assets/images/play-video-button.png";

import {
  testimonial, testimonialSlider, testimonialCardVideoButton,
  testimonialVideoText, testimonialCardPlay, testimonialCardTitle,
  testimonialCard, testimonialCardVideo, testimonialCardVideoButtonContainer,
  testimonialVideoTextParagraph, testimonialVideoTextIcon, testimonialCardTitleMain
} from "./style.mod.scss";

const VideoTestimonial = ({ data }) => {

  const [videoPlay, setVideoState] = useState({
    videos: [
      {
        id: 1, played: false
      },
      {
        id: 2, played: false
      },
      {
        id: 3, played: false
      },
    ]
  });

  const { video } = data;
  const mainTitle = data.title;

  var settings = {
    spaceBetween: 0,
    slidesPerView: 1,
    loop: false,
    pagination: { clickable: true },
    breakpoints: {
      767: {
        slidesPerView: 2,
        spaceBetween: 50,
      },
      992: {
        pagination: false,
        slidesPerView: 3,
        spaceBetween: 30,
        noSwiping: true,
        noSwipingClass:"swiper-wrapper",
      },
      1200: {
        noSwiping: true,
        noSwipingClass:"swiper-wrapper",
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1399: {
        noSwiping: true,
        noSwipingClass:"swiper-wrapper",
        slidesPerView: 3,
        spaceBetween: 70,
      },
      8000: { pagination: false },
    }
  };

  function setVideoActive(index) {
    let videoArrCopy = [...videoPlay.videos];

    videoArrCopy[index].played = !videoArrCopy[index].played;

    setVideoState (
      {
        ...videoPlay,
        videos: videoArrCopy
      }
    );
  }

  function endvideo(index) {
    let videoArrCopy = [...videoPlay.videos];

    videoArrCopy[index].played = false;

    setVideoState (
      {
        ...videoPlay,
        videos: videoArrCopy
      }
    );
  }

  function toggleActiveVideo(index) {
    if (videoPlay.videos[index].played) {
      return testimonialCardPlay;
    }

    return "";
  }

  return (
    <div className={testimonial}>
      <Container>
        <h2 className={`${testimonialCardTitle} ${testimonialCardTitleMain}`}>{mainTitle}</h2>
        <Swiper {...settings} className={testimonialSlider} >
          {
            video?.map((singleVideo, index) => {
              const { title, thumbnail, video, icon } = singleVideo;
              return (
                <SwiperSlide key={index}>
                  {
                    (index === 1) &&
                    (
                      <h2 className={testimonialCardTitle}>{mainTitle}</h2>
                    )
                  }
                  <div onClick={() => setVideoActive(index)} className={`${testimonialCard} ${toggleActiveVideo(index)}`}>
                    <Player playsInline className={testimonialCardVideo} poster={getImagePath(thumbnail)} src={getFilePath(video)} onEnded={() => endvideo(index)}>
                      <button className={testimonialCardVideoButtonContainer}>
                        <img className={testimonialCardVideoButton} src={playVideoButton} />
                      </button>
                      <BigPlayButton disabled/>
                      <ControlBar disabled/>
                    </Player>
                    <div className={testimonialVideoText}>
                      <img className={testimonialVideoTextIcon} srcSet={getImagePath(icon, true)} />
                      <p className={testimonialVideoTextParagraph}>{title}</p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
      </Container>
    </div>
  );
};

export default VideoTestimonial;
