// extracted by mini-css-extract-plugin
export var testimonial = "style-mod--testimonial--1F3FM";
export var testimonialCardTitleMain = "style-mod--testimonial-card-title-main--1as14";
export var testimonialSlider = "style-mod--testimonial-slider--19m74";
export var testimonialCard = "style-mod--testimonial-card--ecPPm";
export var testimonialCardTitle = "style-mod--testimonial-card-title--2TE8W";
export var testimonialVideoText = "style-mod--testimonial-video-text--2JgQU";
export var testimonialVideoTextIcon = "style-mod--testimonial-video-text-icon--bk3mk";
export var testimonialVideoTextParagraph = "style-mod--testimonial-video-text-paragraph--3hZjc";
export var testimonialCardVideo = "style-mod--testimonial-card-video--a5-qO";
export var testimonialCardVideoButtonContainer = "style-mod--testimonial-card-video-button-container--1uKVl";
export var testimonialCardVideoButton = "style-mod--testimonial-card-video-button--2WIQn";
export var testimonialCardPlay = "style-mod--testimonial-card-play--1aP5w";